<template>
  <ka-auth-page v-if="postSignUpStatus.isSuccess" full-height>
    <div class="finish-page-content finish-page-grid">
      <h1 class="tw-heading-1">{{ $t('global.titles.welcome') }}</h1>

      <p>{{ $t('auth.descriptions.check_welcome_email') }}</p>

      <k-button
        color="primary"
        @click="finishSignUp"
      >
        {{ $t('global.actions.complete') }}
      </k-button>
    </div>
  </ka-auth-page>

  <k-layout v-else no-header no-footer class="tw-bg-gray-100">
    <main class="tw-h-full tw-px-5 md:tw-px-10 lg:tw-px-14 tw-py-12 md:tw-py-20 lg:tw-py-24">
      <div class="tw-max-w-7xl tw-mx-auto">
        <div class="tw-flex tw-flex-col tw-gap-6 md:tw-gap-10 lg:tw-flex-row">
          <div class="tw-flex-shrink-0 lg:tw-max-w-xs">
            <h1
              class="tw-text-2xl tw-leading-1.4em md:tw-text-2.5xl md:tw-leading-1.25em lg:tw-text-3.5xl tw-font-bold tw-text-gray-800"
              v-html="$t('profile.subtitles.finish_signup')"
            />
          </div>
          <div
            class="tw-bg-white tw-rounded-2xl tw-px-6 tw-py-8 md:tw-px-12 md:tw-py-12 lg:tw-px-14 lg:tw-py-14 tw-w-full"
          >
            <form @submit.prevent="submit" class="tw-grid tw-gap-6 md:tw-gap-8">
              <div class="tw-grid md:tw-grid-cols-2 tw-gap-6 md:tw-gap-8">
                <k-input
                  v-model="firstName"
                  :title="$t('global.inputs.first_name')"
                  :invalid="errorField === 'firstName'"
                />

                <k-input
                  v-model="lastName"
                  :title="$t('global.inputs.last_name')"
                  :invalid="errorField === 'lastName'"
                />

                <div>
                  <k-phone
                    ref="phone"
                    @update-phone-number="phoneNumber = $event"
                  />
                </div>
              </div>

              <div class="tw-flex tw-flex-col-reverse tw-gap-6 md:tw-flex-row md:tw-items-center">
                <k-combobox
                  v-model="profession"
                  :items="professions"
                  :title="$t('profile.inputs.profession_finish_up')"
                  :invalid="errorField === 'profession'"
                  :disabled="isNonItUser"
                  item-value="value"
                  item-text="displayString"
                />

                <k-checkbox
                  :label="$t('profile.inputs.non_it')"
                  v-model="isNonItUser"
                  class="md:tw-pt-6"
                />
              </div>

              <template v-if="!isNonItUser">
                <div>
                  <k-autocomplete-technologies
                    v-model="expertises"
                    :label="$t('profile.inputs.technologies_finish_up')"
                    :show-limit="3"
                    :invalid="errorField === 'expertises'"
                  />
                </div>

                <div class="tw-flex tw-flex-col">
                <span class="tw-font-bold tw-text-sm tw-text-gray-800 tw-pb-2">
                  {{ $t('profile.job_timeframe_finish_up') }}
                </span>

                  <k-date-picker
                    v-model="availableFrom"
                    :invalid="errorField === 'availableFrom'"
                    input-class="tw-w-max-content"
                  />
                </div>
              </template>

              <div>
                <k-gdpr
                  class="mt-2"
                  v-model="gdpr"
                  :showNewsletter="false"
                  :invalid="errorField === 'gdpr'"
                  :gdprTooltip="$t('gdpr.descriptions.register_account_tooltip')"
                />
              </div>

              <v-expand-transition :class="{ 'tw-mt-': !!postSignUpStatus.error }">
                <div v-show="postSignUpStatus.error" class="form-error-message tw-m-0 tw-p-0">
                  <p>{{ postSignUpStatus.error }}</p>
                </div>
              </v-expand-transition>

              <v-expand-transition :class="{ 'tw-mt-': !!errorMessage }">
                <div v-show="errorMessage" class="form-error-message tw-m-0 tw-p-0">
                  <p>{{ $t(errorMessage) }}</p>
                </div>
              </v-expand-transition>

              <div class="tw-flex tw-items-center tw-gap-x-4">
                <k-button
                  color="primary"
                  type="submit"
                  :loading="postSignUpStatus.isLoading"
                >
                  {{ $t('global.actions.save') }}
                </k-button>

                <k-button
                  color="red-outlined"
                  :loading="deleteAccountStatus.isLoading"
                  @click="deleteAccount"
                >
                  {{ $t('global.actions.delete_account') }}
                </k-button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </main>
  </k-layout>
</template>

<script>
import { mapGetters } from 'vuex'
// import KaSuggestTechnologies from "@web/components/ka-suggest-technologies";
import { redirectAfterSuccessfulAfterSignUp } from '@web/auth/redirect'
import KaAuthPage from '@web/auth/ka-auth-page.vue'
import KDatePicker from '@web/components/core/k-date-picker.vue'
import KPhone from '@web/components/core/k-phone.vue'
import KCheckbox from '@web/components/core/k-checkbox.vue'

export default {
  components: {
    KCheckbox,
    KPhone,
    KDatePicker,
    KaAuthPage
    // KaSuggestTechnologies
  },
  data() {
    return {
      firstName: null,
      lastName: null,
      phoneNumber: null,
      profession: null,
      expertises: [],
      availableFrom: null,
      errorMessage: null,
      gdpr: null,
      errorField: null,
      isNonItUser: false
    }
  },
  computed: {
    ...mapGetters('ENUMS', {
      professions: 'getProfessions'
    }),
    postSignUpStatus() {
      return this.$store.getters['AUTH/getStatus']('postSignUpStatus')
    },
    deleteAccountStatus() {
      return this.$store.getters['AUTH/getStatus']('deleteAccountStatus')
    },
    nonItProfession() {
      return this.professions.find(profession => profession.value === 'NON_IT') || null
    }
  },
  methods: {
    deleteAccount() {
      this.$store.dispatch('AUTH/deleteAccount')
    },
    validate() {
      if (!(this.firstName && String(this.firstName).trim().length > 0)) {
        this.errorField = 'firstName'
        this.errorMessage = 'global.form_errors.enter_name'

        return false
      }

      if (!(this.lastName && String(this.lastName).trim().length > 0)) {
        this.errorField = 'lastName'
        this.errorMessage = 'global.form_errors.enter_last_name'

        return false
      }

      const phoneValidation = this.$refs.phone.validate()

      if (!phoneValidation.isValid) {
        this.errorMessage = phoneValidation.errorMessage

        return false
      }

      if (!this.profession) {
        this.errorField = 'profession'
        this.errorMessage = 'global.form_errors.forgot_profession'

        return false
      }

      if (this.expertises.length <= 1 && !this.isNonItUser) {
        this.errorField = 'expertises'
        this.errorMessage = 'global.form_rules.min_1_technology'

        return false
      }

      if (!this.availableFrom) {
        this.errorField = 'availableFrom'
        this.errorMessage = 'global.form_errors.select_available_from_date'

        return false
      }

      if (!(this.gdpr && this.gdpr.gdprValue === true)) {
        this.errorField = 'gdpr'
        this.errorMessage = 'global.form_rules.accept_terms_conditions'

        return false
      }

      this.errorField = null
      this.errorMessage = null

      return true
    },
    submit() {
      const valid = this.validate()

      if (!valid) {
        return
      }

      this.$store.dispatch('AUTH/registerNewUserAfterSignUp', {
        profession: this.profession,
        expertises: this.expertises,
        firstName: this.firstName,
        lastName: this.lastName,
        phoneNumber: this.phoneNumber,
        availableFrom: this.availableFrom
      })
    },
    finishSignUp() {
      redirectAfterSuccessfulAfterSignUp()
    }
  },
  watch: {
    isNonItUser(value) {
      this.profession = value ? this.nonItProfession : null
      this.availableFrom = '2100-01-01'
    }
  }
}
</script>

<style lang="scss" scoped>
.form-error-message p {
  margin-top: 0 !important;
}

.finish-page-content {
  width: 22.5rem;
  margin: auto;
}

.finish-page-grid {
  display: grid;
  gap: 2rem;
}
</style>
