import { render, staticRenderFns } from "./ka-user-finish-signup.vue?vue&type=template&id=006c9504&scoped=true&"
import script from "./ka-user-finish-signup.vue?vue&type=script&lang=js&"
export * from "./ka-user-finish-signup.vue?vue&type=script&lang=js&"
import style0 from "./ka-user-finish-signup.vue?vue&type=style&index=0&id=006c9504&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "006c9504",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import KAutocompleteTechnologies from '@web/components/core/k-autocomplete-technologies'
import KButton from '@web/components/core/k-button'
import KCheckbox from '@web/components/core/k-checkbox'
import KCombobox from '@web/components/core/k-combobox'
import KDatePicker from '@web/components/core/k-date-picker'
import KGdpr from '@web/components/core/k-gdpr'
import KInput from '@web/components/core/k-input'
import KLayout from '@web/components/core/k-layout'
import KPhone from '@web/components/core/k-phone'
import { VExpandTransition } from 'vuetify/lib/components/transitions';
installComponents(component, {KAutocompleteTechnologies,KButton,KCheckbox,KCombobox,KDatePicker,KGdpr,KInput,KLayout,KPhone,VExpandTransition})
